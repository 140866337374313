import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel, AccordionItemState } from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import Attorneys from "../components/Repeating/Attorneys";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonWithUnderline from "../components/Button/ButtonWithUnderline";

export const query = graphql`
   query PracticeAreasTemplateQuery($id: String!) {
      page: sanityPracticeAreas(id: { eq: $id }) {
         id
         seoTitle
         metaDescription
         openGraphImage {
            asset {
               url
            }
         }
         twitterCardImage {
            asset {
               url
            }
         }
         heroImage {
            asset {
               gatsbyImageData(layout: CONSTRAINED, width: 1376)
            }
         }
         heroHeading
         _rawHeroText(resolveReferences: { maxDepth: 5 })
         _rawTextBlock1(resolveReferences: { maxDepth: 5 })
         _rawTextBlock2(resolveReferences: { maxDepth: 5 })
         _rawTextBlock3(resolveReferences: { maxDepth: 5 })
         _rawAccordionHeading1(resolveReferences: { maxDepth: 5 })
         _rawAccordionHeading2(resolveReferences: { maxDepth: 5 })
         _rawAccordionHeading3(resolveReferences: { maxDepth: 5 })
         accordion1 {
            title
            _rawText(resolveReferences: { maxDepth: 5 })
            link
         }
         accordion2 {
            title
            _rawText(resolveReferences: { maxDepth: 5 })
            link
         }
         accordion3 {
            title
            _rawText(resolveReferences: { maxDepth: 5 })
            link
         }
         sidebar {
            navHeading
            specialties {
               title
               link
            }
            ctaHeading
         }
         teamMembers {
            title
         }
         testimonials {
            title
         }
         blogs {
            title
         }
      }
      infoGraphic: file(relativePath: { eq: "repeating/Personal Injury Infographic Version II - desktop.svg" }) {
         publicURL
       }
       infoGraphicMob: file(relativePath: { eq: "repeating/Personal Injury Infographic Version II - mobile.svg" }) {
         publicURL
       }
      
   }
`;

const PracticeAreasTemplate = (props) => {
   let slug = "";
   if (typeof window !== "undefined") {
      slug = window.location.pathname;
   }

   const StyledContent = styled.div`
      ul {
         ${tw`space-y-2 mb-6`}
         li {
            ${tw`relative pl-10 before:min-w-[24px] before:h-0.5 before:bg-primary-600 before:absolute before:left-0 before:top-2.5`}
         }
      }
      a {
         ${tw`text-primary-600`}
      }
      iframe {
         ${tw`max-w-xl`}
      }
   `;

   const { data, errors } = props;
   const page = data && data.page;
   return (
      <Layout>
         {errors && <SearchEngineOptimization title="GraphQL Error" />}
         {page && (
            <SearchEngineOptimization
               title={page.seoTitle}
               description={page.metaDescription}
               openGraphImage={page.openGraphImage && page.openGraphImage.asset.url}
               twitterOpenGraphImage={page.twitterCardImage && page.twitterCardImage.asset.url}
            />
         )}

         {errors && (
            <div className="container">
               <GraphQLErrorList errors={errors} />
            </div>
         )}

         {page && (
            <>
               <section className="relative pb-14 md:pb-32">
                  <div className="container">
                     <div className="grid md:grid-cols-2 gap-y-16">
                        <div className="md:pt-28 md:pb-36 md:pr-14 lg:pr-20 xl:pr-28 order-2 md:order-1">
                           <div className="bg-gray-900 text-gray-300 uppercase px-2.5 py-1.5 inline-flex items-center justify-center mb-5 md:mb-6">
                              since 1989
                           </div>
                           <h1>{page.heroHeading}</h1>
                           <div className="md:pl-8 lg:pl-12 md:pt-6">
                              {page._rawHeroText ? (
                                 <>
                                    <div className="pb-1.5 md:pb-7">
                                       <PortableText blocks={page._rawHeroText} />
                                    </div>
                                    <div className="grid lg:grid-cols-2 items-center gap-4">
                                       <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full lg:w-auto" />

                                       {slug.includes("personal-injury") && (
                                          <ButtonGhost href="/guide-personal-injury-clients/" text="Resources" className="w-full lg:w-auto" />
                                       )}
                                    </div>
                                 </>
                              ) : (
                                 <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full md:w-auto" />
                              )}
                           </div>
                        </div>
                        <div className="order-1 md:order-2 relative md:h-full -mx-4 md:mx-0">
                           <div className="md:absolute md:left-0 md:w-[50vw] h-full">
                              {page.heroImage && (
                                 <GatsbyImage image={page.heroImage.asset.gatsbyImageData} alt={page.heroHeading} className="w-full h-full" />
                              )}
                           </div> 
                        </div>
                     </div>
                  </div>
               </section>
               <section className="relative pb-20 md:pb-32">
                  <div className="container">
                     <div className="grid md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                        <div className="md:col-start-1 md:col-span-8">
                           <StyledContent>
                              {page._rawTextBlock1 && (
                                 <div className="mb-16 md:mb-14">
                                    <PortableText blocks={page._rawTextBlock1} />
                                 </div>
                              )}

                              {slug.includes("personal-injury") && (
                                <ButtonSolid modal="modal-contact" text="Request a Free Consultation" className="" />      
                              )}

                              {(page.accordion1.length > 0 || page._rawAccordionHeading1) && (
                                 <div className="mb-16 md:mb-14 mt-16">
                                    {page._rawAccordionHeading1 && (
                                       <div className="mb-16 md:mb-14">
                                          <PortableText blocks={page._rawAccordionHeading1} />
                                       </div>
                                    )}

                                    {page.accordion1.length > 0 && (
                                       <Accordion allowZeroExpanded={true} className="mb-8 border-t border-gray-100">
                                          {page.accordion1.map((item, i) => {
                                             return (
                                                <div key={i}>
                                                   <AccordionItem className="py-6 border-b border-gray-100" uuid={i}>
                                                      <AccordionItemButton className="flex items-center justify-between hover:outline-none">
                                                         <AccordionItemState>
                                                            {(state) => {
                                                               return (
                                                                  <>
                                                                     <h3 className="heading-six mb-0">{item.title}</h3>

                                                                     <i
                                                                        className={`far ${
                                                                           state.expanded ? "fa-minus" : "fa-chevron-down"
                                                                        } text-2xl text-primary-600`}
                                                                     ></i>
                                                                  </>
                                                               );
                                                            }}
                                                         </AccordionItemState>
                                                      </AccordionItemButton>
                                                      <AccordionItemPanel className="pt-6 pb-1.5 animate-fadeIn">
                                                         {item._rawText && (
                                                            <div>
                                                               <PortableText blocks={item._rawText} />
                                                            </div>
                                                         )}

                                                         {item.link && <ButtonWithUnderline href={item.link} text="Learn More" />}
                                                      </AccordionItemPanel>
                                                   </AccordionItem>
                                                </div>
                                             );
                                          })}
                                       </Accordion>
                                    )}
                                 </div>
                              )}

                              {page._rawTextBlock2 && (
                                 <div className="mb-16 md:mb-14">
                                    <PortableText blocks={page._rawTextBlock2} />
                                 </div>
                              )}

                              {(page.accordion2.length > 0 || page._rawAccordionHeading2) && (
                                 <div className="mb-16 md:mb-14">
                                    {page._rawAccordionHeading2 && (
                                       <div className="mb-16 md:mb-14">
                                          <PortableText blocks={page._rawAccordionHeading2} />
                                       </div>
                                    )}

                                    {page.accordion2.length > 0 && (
                                       <Accordion allowZeroExpanded={true} className="mb-8 border-t border-gray-100">
                                          {page.accordion2.map((item, i) => {
                                             return (
                                                <div key={i}>
                                                   <AccordionItem className="py-6 border-b border-gray-100" uuid={i}>
                                                      <AccordionItemButton className="flex items-center justify-between hover:outline-none">
                                                         <AccordionItemState>
                                                            {(state) => {
                                                               return (
                                                                  <>
                                                                     <h3 className="heading-six mb-0">{item.title}</h3>

                                                                     <i
                                                                        className={`far ${
                                                                           state.expanded ? "fa-minus" : "fa-chevron-down"
                                                                        } text-2xl text-primary-600`}
                                                                     ></i>
                                                                  </>
                                                               );
                                                            }}
                                                         </AccordionItemState>
                                                      </AccordionItemButton>
                                                      <AccordionItemPanel className="pt-6 pb-1.5 animate-fadeIn">
                                                         {item._rawText && (
                                                            <div>
                                                               <PortableText blocks={item._rawText} />
                                                            </div>
                                                         )}

                                                         {item.link && <ButtonWithUnderline href={item.link} text="Learn More" />}
                                                      </AccordionItemPanel>
                                                   </AccordionItem>
                                                </div>
                                             );
                                          })}
                                       </Accordion>
                                    )}
                                 </div>
                              )}

                              {page._rawTextBlock3 && (
                                 <div className="mb-16 md:mb-14">
                                    <PortableText blocks={page._rawTextBlock3} />
                                 </div>
                              )}

                              {(page.accordion3.length > 0 || page._rawAccordionHeading3) && (
                                 <div className="mb-16 md:mb-14">
                                    {page._rawAccordionHeading3 && (
                                       <div className="mb-16 md:mb-14">
                                          <PortableText blocks={page._rawAccordionHeading3} />
                                       </div>
                                    )}

                                    {page.accordion3.length > 0 && (
                                       <Accordion allowZeroExpanded={true} className="mb-8 border-t border-gray-100">
                                          {page.accordion3.map((item, i) => {
                                             return (
                                                <div key={i}>
                                                   <AccordionItem className="py-6 border-b border-gray-100" uuid={i}>
                                                      <AccordionItemButton className="flex items-center justify-between hover:outline-none">
                                                         <AccordionItemState>
                                                            {(state) => {
                                                               return (
                                                                  <>
                                                                     <h3 className="heading-six mb-0">{item.title}</h3>

                                                                     <i
                                                                        className={`far ${
                                                                           state.expanded ? "fa-minus" : "fa-chevron-down"
                                                                        } text-2xl text-primary-600`}
                                                                     ></i>
                                                                  </>
                                                               );
                                                            }}
                                                         </AccordionItemState>
                                                      </AccordionItemButton>
                                                      <AccordionItemPanel className="pt-6 pb-1.5 animate-fadeIn">
                                                         {item._rawText && (
                                                            <div>
                                                               <PortableText blocks={item._rawText} />
                                                            </div>
                                                         )}

                                                         {item.link && <ButtonWithUnderline href={item.link} text="Learn More" />}
                                                      </AccordionItemPanel>
                                                   </AccordionItem>
                                                </div>
                                             );
                                          })}
                                       </Accordion>
                                    )}
                                 </div>
                              )}
                           </StyledContent>

                           

                           {slug.includes("personal-injury") && (
                                 <div className="mb-12 relative">
                                    <h2 className="mt-12">Timeline of a Personal Injury Case</h2>
                                    <div className="hidden md:block">
                                       <img src={data.infoGraphic.publicURL} alt="InfoGraphic" />
                                   </div>
                                   <div className="md:hidden">
                                       <img src={data.infoGraphicMob.publicURL} alt="InfoGraphic" />
                                   </div>
                                 </div>
                                 
                                 
                              )}

                           <ButtonSolid modal="modal-contact" text="Contact Us" className="hidden md:inline-flex" />
                        </div>

                        <div className="md:col-end-13 md:col-span-4 md:max-w-[300px] md:ml-auto">
                           <div className="md:top-32 md:sticky">
                              {page.sidebar && page.sidebar.navHeading && (
                                 <p className="text-xl font-normal text-secondary-50">{page.sidebar.navHeading}</p>
                              )}
                              {page.sidebar && page.sidebar.specialties && (
                                 <ul className="mb-12 space-y-2">
                                    {page.sidebar.specialties.map((item, i) => {
                                       return (
                                          <li key={i} className="text-sm font-bold inline-flex w-full">
                                             <AniLink
                                                fade
                                                to={item.link}
                                                className={`text-primary-600 hover:text-primary-50 no-underline flex items-center ${
                                                   slug === item.link && "text-primary-50"
                                                }`}
                                             >
                                                {slug === item.link && <div className="w-4 h-0.5 bg-primary-50 mr-2.5"></div>}
                                                {item.title}
                                             </AniLink>
                                          </li>
                                       );
                                    })}
                                 </ul>
                              )}

                              {slug.includes("personal-injury") && (
                                 <div className="mb-12 relative">
                                    <p className="text-xl font-normal text-secondary-50 mb-5">Worried About Paying?</p>
                                    <p className="text-sm font-normal text-primary-50 mb-0">
                                       At Jorgensen, Brownell & Pepin, P.C., we accept personal injury cases on a contingency fee basis.{" "}
                                       <span className="group">
                                          <i className="fa-light fa-circle-info text-primary-500"></i>
                                          <span className="invisible absolute bottom-6 left-0 z-10 w-[240px] rounded-sm bg-black p-4 text-xs text-white opacity-0 transition duration-300 ease-linear group-hover:visible group-hover:opacity-100">
                                             At Jorgensen, Brownell & Pepin, P.C., we accept personal injury cases on a contingency fee basis, which
                                             means we don't get paid unless you do. You are only responsible for paying us only if we win your case,
                                             and our fee will come out of the money that is awarded to you.
                                          </span>
                                       </span>
                                    </p>
                                 </div>
                              )}

                              {page.sidebar && page.sidebar.ctaHeading && (
                                 <p className="text-xl font-normal text-secondary-50">{page.sidebar.ctaHeading}</p>
                              )}
                              <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full md:w-auto" />
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
               <div
                  style={{
                     background: "linear-gradient(5.55deg, #26272B 16.02%, #838A9D 87.03%)",
                  }}
               >
                  <Testimonials />

                  {page.teamMembers ? <Attorneys category={page.teamMembers.title} /> : <Attorneys />}
               </div>
               {page.blogs ? <RecentBlogPosts category={page.blogs.title} /> : <RecentBlogPosts />}
               <CallToAction />
            </>
         )}
      </Layout>
   );
};

export default PracticeAreasTemplate;
